.app__works {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__work-filter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    margin: 2rem 0 2rem;

    .app__work-filter-item {
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        background-color: #fff;
        color: #000;
        font-weight: 800;

        cursor: pointer;
        transition: all 0.3s ease;
        margin: 0.5rem;

        &:hover {
            background-color: var(--secondary-color);
            color: #fff;
        }

        @media screen and (min-width: 2000px) {
            padding: 1rem 2rem;
            border-radius: 0.85rem;
        }
    }

    .item-active {
        background-color: var(--secondary-color);
        color: #fff;
    }
}

.app__work-portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .app__work-item {
        width: 270px;
        flex-direction: column;

        margin: 2rem;
        padding: 1rem;
        border-radius: 0.5rem;
        background-color: #fff;
        color: #000;

        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
            box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
        }

        @media screen and (min-width: 2000px) {
            width: 470px;
            padding: 1.25rem;
            border-radius: 0.75rem;
        }

        @media screen and (max-width: 300px) {
            width: 100%;
            margin: 1rem;
        }
    }
}

.app__work-img {
    width: 100%;
    height: 230px;

    position: relative;

    img {
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;
        object-fit: cover;
    }

    @media screen and (min-width: 2000px) {
        height: 350px;
    }
}

.app__work-hover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    border-radius: 0.5rem;
    opacity: 0;
    transition: all 0.3s ease;

    div {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;

        margin: 1rem;
        font-family: var(--font-base);
        font-weight: 800;
        cursor: pointer;
        transition: all 0.3s ease;

        svg {
            width: 50%;
            height: 50%;
            color: var(--white-color);
        }
    }
}

.app__work-content {
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;

    h4 {
        margin-top: 1rem;
        line-height: 1.5;

        @media screen and (min-width: 2000px) {
            margin-top: 3rem;
        }
    }

    .app__work-tag {
        position: absolute;

        padding: 0.5rem 1rem;
        border-radius: 10px;
        background-color: #fff;
        top: -25px;
    }
}

.popup_container {

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.75);
    padding-top: 10%;


}

.popup_body {
    width: 90%;
    height: 90%;
    background-color: var(--white-color);
    margin: auto;
    overflow: auto;



}

.popup_header {
    width: 100%;
    text-align: right;
    padding: 1.5rem;

    @media screen and (min-width: 900) {
        width: 80%;
        padding: 5;
        text-align: center;
    }

}

.popup_content {

    display: flex;
    flex-direction: column;
    padding: 1rem;

}

.popup_card {

    display: flex;
    flex-direction: column;

    img {
        width: 60%;
        height: auto;
        margin: auto;
    }

    h2 {
        margin-top: 10;

        @media screen and (min-width: 900) {
            font-size: 22px;
        }
    }

    p {
        text-align: justify;
        font-size: 18px;
        line-height: 2.2;
        padding: 1.5rem;

        @media screen and (min-width: 700) {
            text-align: left;
            padding: 10;
            font-size: 14px;
            line-height: 1.1;
        }
    }


}

.app__search-container {
    padding-top: 1.5rem;
    width: 100%;
}

.app__searchbar {
    width: 80%;
    height: 40px;
    border-radius: 12px;
    border: 1px solid #959797;
    padding: 1rem;
    font-size: 19px;

    @media screen and (min-width: 1000px) {
        width: 50%;
    }
}